/* for hiding process is not defined bug from console */
body
  > iframe[style*='2147483647']:not([id='webpack-dev-server-client-overlay']) {
  display: none;
}
.chart {
  height: 320px !important;
  width: 320px !important;
  justify-items: center;
  margin: auto;
}

.drawer {
  position: fixed;
  z-index: 9999;
  transition: width 0s ease 0.3s, height 0s ease 0.3s,
    transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer > * {
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    box-shadow 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer.drawer-open {
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer .drawer-mask {
  background: #000;
  opacity: 0;
  width: 100%;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    height 0s ease 0.3s;
}
.drawer-content-wrapper {
  position: absolute;
  background: #fff;
}
.drawer-content {
  overflow: auto;
  z-index: 1;
  position: relative;
}
.drawer-handle {
  position: absolute;
  top: 72px;
  width: 41px;
  height: 40px;
  cursor: pointer;
  z-index: 0;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  display: none;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.drawer-handle-icon {
  width: 14px;
  height: 2px;
  background: #333;
  position: relative;
  transition: background 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer-handle-icon:before,
.drawer-handle-icon:after {
  content: '';
  display: block;
  position: absolute;
  background: #333;
  width: 100%;
  height: 2px;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer-handle-icon:before {
  top: -5px;
}
.drawer-handle-icon:after {
  top: 5px;
}
.drawer-left,
.drawer-right {
  width: 0%;
  height: 100%;
}
.drawer-left .drawer-content-wrapper,
.drawer-right .drawer-content-wrapper,
.drawer-left .drawer-content,
.drawer-right .drawer-content {
  height: 100%;
}
.drawer-left.drawer-open,
.drawer-right.drawer-open {
  width: 100%;
}
.drawer-left.drawer-open.no-mask,
.drawer-right.drawer-open.no-mask {
  width: 0%;
}
.drawer-left {
  top: 0;
  left: 0;
}
.drawer-left .drawer-handle {
  right: -40px;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 4px 4px 0;
}
.drawer-left.drawer-open .drawer-content-wrapper {
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}
.drawer-right {
  top: 0;
  right: 0;
}
.drawer-right .drawer-content-wrapper {
  right: 0;
}
.drawer-right .drawer-handle {
  left: -40px;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px 0 0 4px;
}
.drawer-right.drawer-open .drawer-content-wrapper {
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
}
.drawer-right.drawer-open.no-mask {
  right: 1px;
  transform: translateX(1px);
}
.drawer-top,
.drawer-bottom {
  width: 100%;
  height: 0%;
}
.drawer-top .drawer-content-wrapper,
.drawer-bottom .drawer-content-wrapper,
.drawer-top .drawer-content,
.drawer-bottom .drawer-content {
  width: 100%;
}
.drawer-top .drawer-content,
.drawer-bottom .drawer-content {
  height: 100%;
}
.drawer-top.drawer-open,
.drawer-bottom.drawer-open {
  height: 100%;
}
.drawer-top.drawer-open.no-mask,
.drawer-bottom.drawer-open.no-mask {
  height: 0%;
}
.drawer-top .drawer-handle,
.drawer-bottom .drawer-handle {
  left: 50%;
  margin-left: -20px;
}
.drawer-top {
  top: 0;
  left: 0;
}
.drawer-top .drawer-handle {
  top: auto;
  bottom: -40px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 4px 4px;
}
.drawer-top.drawer-open .drawer-content-wrapper {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.drawer-bottom {
  bottom: 0;
  left: 0;
}
.drawer-bottom .drawer-content-wrapper {
  bottom: 0;
}
.drawer-bottom .drawer-handle {
  top: -40px;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px 4px 0 0;
}
.drawer-bottom.drawer-open .drawer-content-wrapper {
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
}
.drawer-bottom.drawer-open.no-mask {
  bottom: 1px;
  transform: translateY(1px);
}
.drawer.drawer-open .drawer-mask {
  opacity: 0.3;
  height: 100%;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer.drawer-open .drawer-handle-icon {
  background: transparent;
}
.drawer.drawer-open .drawer-handle-icon:before {
  transform: translateY(5px) rotate(45deg);
}
.drawer.drawer-open .drawer-handle-icon:after {
  transform: translateY(-5px) rotate(-45deg);
}

/* drawer */
.drawer {
  overflow: visible;
  z-index: 9989 !important;
}
.drawer .drawer-content-wrapper {
  width: 50vw;
  transform: translateX(0px);
}
.drawer .drawer-content-wrapper .drawer-handle {
  display: none !important;
}
@media (min-width: 320px) and (max-width: 767px) {
  .drawer .drawer-content-wrapper {
    width: 100vw;
  }
}

/* modal */
.custom-modal header button {
  display: none;
}

/*input tag css */
.react-tag-input {
  width: 100%;
  background: #f4f5f7;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  padding: 0;
}
.react-tag-input__input {
  font-size: 0.875rem;
  margin: 0;
  height: 3rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  background: transparent;
  color: #4c4f52;
}
.react-tag-input__input:focus {
  background: #ffffff;
}
.react-tag-input__input::placeholder,
.react-tag-input__input:-moz-placeholder,
.react-tag-input__input:-ms-input-placeholder,
.react-tag-input__input::-moz-placeholder,
.react-tag-input__input::-webkit-input-placeholder {
  font-size: 10px;
  font-weight: 400;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.react-tag-input__tag {
  margin: 0.175rem 0.375rem;
  color: #24262d;
}
.theme-dark .react-tag-input {
  color: #ebebeb;
  border-color: #4c4f52;
  background-color: #24262d;
}
.theme-dark .react-tag-input__input {
  color: #ebebeb;
}
.theme-dark .react-tag-input__input:focus {
  background-color: #24262d;
  border-color: #707275;
}

/* dropdown style */
.dropdown {
  display: none !important;
}

/* notification box css */

.notification-box {
  width: 20rem;
  height: 22rem;
}
.notification-content h6 {
  font-size: 13px;
  margin-bottom: 5px;
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.notification-content p span {
  font-size: 11px;
}

.modalSize {
  width: 80% !important;
  height: auto;
}

.p-multiselect .p-multiselect-trigger {
  background: rgba(36, 38, 45) !important;
  color: #6c757d !important;
  width: 3rem !important;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.p-multiselect-panel {
  background: rgba(36, 38, 45) !important;
  color: #495057 !important;
  border: 0 none !important;
  border-radius: 6px !important;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%) !important;
}

.p-checkbox .p-checkbox-box {
  border: 2px solid #ced4da !important;
  background: rgba(36, 38, 45) !important;
  width: 22px;
  height: 22px;
  color: #495057 !important;
  border-radius: 6px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-multiselect-panel .p-multiselect-header {
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid #dee2e6 !important;
  color: #343a40 !important;
  background: rgba(36, 38, 45) !important;
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.p-multiselect .p-multiselect-label.p-placeholder {
  color: #ffffff !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  color: #ffffff !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  background: #495057 !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background: #495057 !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0rem #495057 !important;
}

/* Style the scrollbar for webkit-based browsers */
.container::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #000 !important;
}

.container::-webkit-scrollbar-thumb {
  background-color: #000 !important;
  border-radius: 10px;
}

.container::-webkit-scrollbar-thumb:hover {
  background-color: #555 !important;
}

::-webkit-calendar-picker-indicator  {
  filter: invert(1);
}

.disabledCursor { 
  cursor: default;
}

.p-FieldLabel {
  color: white !important;
}

.img-edit {
  width: 100%;
  background-color: rgb(117, 117, 117);
  aspect-ratio: auto 1/1;
}

.ksZqtO {
  top: 60px !important;
  left: 250px !important;
  width: calc(100% - 250px) !important;
  height: calc(100% - 60px) !important;
}

.bgMain {
  background-color: #040b35;
}

@media (min-width: 320px) and (max-width: 1024px) {
  .ksZqtO {
    top: 60px !important;
    left: 0px !important;
    width: 100% !important;
    height: calc(100% - 60px) !important;
  }
}

@font-face {
  font-family: 'Planner-Bold';
  src: url('../fonts/Planer-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Planner-Reg';
  src: url('../fonts/Planer-Reg.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');
:root {
    --maxWidth: 1200px;
    --width100: 100%;
    --globalPx: 50px;
    --flexDisplay: flex;
    --primaryColor : #040b35;
    --secondaryColor: #f0f3f8;
    --textPrimaryColor : #fff;
    --main-font : 'Montserrat', sans-serif;
    --base-font-size: 0.875rem;
    --small-font-size: 0.75rem;
    --normal-font-size: 1rem;
    --light-font-weight: 300;
    --main-font-weight: 500;
    --bold-font-weight: 700;
    --light-font-weight: 400;
    --base-line-height: 1.5;
    --large-line-height: 1.8;
    --small-line-height: 1.2;
}
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
html,body {
    max-width: 100vw;
    overflow-x: hidden;
    font-family: var(--main-font);
}
ul, p, h1, h2, h3, h4, h5, h6{
    padding: 0;
    margin: 0;
}
body a{
    text-decoration: none;
}
body ul{
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
body{
    font-family: var(--main-font) !important;
}
.mt4{margin-top: calc(var(--globalPx)*4);}
.mt10{margin-top: calc(var(--globalPx) - 40px);}
.mt20{margin-top: calc(var(--globalPx) - 30px);}
.mt50{margin-top: var(--globalPx);}
.mt100{margin-top: calc(var(--globalPx)*2);}
.mr1{margin-right: calc(var(--width100) - 99%);}
.ml1{margin-left: calc(var(--width100) - 99%);}
.padGap2{padding: calc(var(--globalPx)*2) 0;}
.padGap3{padding: calc(var(--globalPx)*3) 0;}
.padGap4{padding: calc(var(--globalPx)*4) 0;}
.deepBg{background-color: var(--primaryColor);}
.lightBg{background-color: var(--secondaryColor);}
.width24{width: calc(var(--width100) - 76%);}
.width49{width: calc(var(--width100) - 51%);}
.width100{width: var(--width100);}
.text-right{text-align: right;}
.clearfix{clear: both;}
.aikoContainer {
    margin-right: auto;
    margin-left: auto;
    max-width: var(--maxWidth);
    width: var(--width100);
}
nav.navbar{padding: calc(var(--globalPx) - 25px) 0 calc(var(--globalPx) - 35px);}
.navbar-collapse{
    justify-content: flex-end;
}
.navContent{
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
header .nav-link{color: var(--textPrimaryColor); padding: calc(var(--globalPx) - 40px) calc(var(--globalPx) - 30px);}
header .navbar-nav li:last-child{margin-top: 10px;margin-left: 20px;}
header .nav-link:hover{
    background: #f00d95;
    background: linear-gradient(to right, #f00d95 0%, #9700ed 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}
.logInBtn, .customBtn{
    background: linear-gradient(#040b35, #040b35) padding-box,
              linear-gradient(to right, #f00d95, #9700ed) border-box;
    border-radius: calc(var(--globalPx) - 40px);
    border: 5px solid transparent;
    box-sizing: border-box;
    color: var(--textPrimaryColor);
    text-decoration: none;
}
.customBtn2{
    background: linear-gradient(to right, #f00d95, #9700ed);
    border-radius: calc(var(--globalPx) - 40px);
    color: var(--textPrimaryColor);
    text-decoration: none;
}
.customBtn2:hover{
    background: linear-gradient(to right, #9700ed, #f00d95);
}
.logInBtn:hover{
    background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(to right, #f00d95, #9700ed) border-box;
    color: #040b35;
}
.logInBtn{padding: 0.5rem 2.5rem;}
.customBtn{padding: 1rem 2.5rem;}
.customBtn2{padding: 1.2rem 2.5rem;}
.smallHeading{
    font-size: calc(var(--globalPx) - 30px);
    font-weight: var(--main-font-weight);
}
.mediumHeading{
    font-size: calc(var(--globalPx) - 30px);
    font-weight: var(--bold-font-weight);
    margin: calc(var(--globalPx) - 45px) 0 0;
}
.heading{
    font-size: calc(var(--normal-font-size)*2);
    font-weight: var(--bold-font-weight);
}
.lightBg .heading, .lightBg .smallHeading, .lightBg .mediumHeading{
    color: var(--primaryColor);
}
.deepBg .heading, .deepBg .smallHeading, .deepBg .mediumHeading {
    color: var(--textPrimaryColor);
}
.heading span{
    background: linear-gradient(to right, #f00d95, #9700ed);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.textContent{
    color: var(--primaryColor);
    font-size: var(--base-font-size);
    font-weight: var(--main-font-weight);
    line-height: var(--base-line-height);
}
.gallaryPart{
    display: var(--flexDisplay);
}
.gallaryPart>div{
    margin: 0 0.5%;
}
.gallaryPart ul li, .gallaryPart ul li>div{
    float: left;
}
.gallaryPart ul li img{
    border-radius: calc(var(--globalPx) - 40px);
    margin-bottom: calc(var(--globalPx) - 40px);
}
footer, footer a{
    color: var(--textPrimaryColor);
}
footer a:hover{
    text-decoration: underline;
}
footer address{
    margin: calc(var(--globalPx) - 30px) 0;
}
footer .socialLinks ul li{
    float: left;
    margin: 0 calc(var(--width100) - 95%);
}
footer .socialLinks ul li:first-child{
    margin-left: 0;
}
footer .socialLinks ul li i{
    font-size: calc(var(--globalPx) - 10px);
    color: var(--textPrimaryColor);
}
.footerLower{
    margin-top: var(--globalPx);
    border-top: 1px solid var(--secondaryColor);
    padding: calc(var(--globalPx) - 30px) 0;
}
.footerLower ul li{
    float: right;
    margin: 0 calc(var(--width100) - 99%);
}
.footerLower, .footerLower a{font-size: var(--small-font-size);}
footer.padGap2{padding-bottom: 0;}

@media screen and (max-width: 767px) {
    .aikoContainer{
        margin-right: calc(var(--width100) - 95%);
        margin-left: calc(var(--width100) - 95%);
        width: calc(var(--width100) - 10%);  
    }
    .mtM30{margin-top: calc(var(--globalPx) - 20px);}
}

.mapContainer {
  width: 100%;
  height: 40vh;
}

.header_div {
  display: block;
}

.multi-range-slider .bar-right {
  background-color: white !important;
  border-radius: 0px 0px 0px 0px !important;
  box-shadow: inset 0px 0px 0px black !important;
  height: 2px !important;
}

.multi-range-slider .bar-left {
  width: 25%;
  background-color: white !important;
  border-radius: 0px 0px 0px 0px !important; 
  box-shadow: inset 0px 0px 0px black !important; 
  padding: 0px 0px !important;
  height: 2px !important;
}

.multi-range-slider .bar-inner {
  background-color: red !important;
  border: solid 0px black !important;
  box-shadow: inset 0px 0px 0px black !important;
}

.audio-clip-scroll {
  overflow-x: scroll;
  overflow-y: hidden;
  height: 70px;
  width: 500px;
  border-radius: 10px;
}

.mobile-show {
  display: none !important;
}

.mobile-hidden {
  display: flex !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
/* .audio-clip-scroll::-webkit-scrollbar {
  display: flex;
} */

/* Hide scrollbar for IE, Edge and Firefox*/
/* .audio-clip-scroll {
  -ms-overflow-style: none;  
  scrollbar-width: none;  
}  */

@media screen and (max-width: 768px){
  .header_div {
    display: none;
  }

  .mobile-show {
    display: flex !important;
  }
  
  .mobile-hidden {
    display: none !important;
  }
  
  .audio-clip-scroll {
    overflow-x: scroll;
    overflow-y: hidden;
    height: 50px;
    width: 300px;
    border-radius: 10px;
  }  
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* .audio-clip-scroll::-webkit-scrollbar {
    display: none;
  } */
}